"use strict";

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {
      projectIdSelected: ""
    };
  },
  computed: {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.setting.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.$store.state.setting.sidebar.opened;
    },
    userRoutes: function userRoutes() {
      console.log(this.$store.state.account.routes);
      return this.$store.state.account.routes;
    },
    type: function type() {
      return this.$store.state.account.user.type;
    },
    tenantId: function tenantId() {
      return this.$store.state.account.user.tenantId;
    },
    options: function options() {
      return this.$store.state.account.projectPermision;
    }
  },
  methods: {
    changeProject: function changeProject() {
      this.$store.commit('account/setProjectIdSelected', this.projectIdSelected);
      location.reload();
    }
  },
  mounted: function mounted() {
    console.log(this.options);

    if (this.type == 2 || this.type == 3) {
      this.projectIdSelected = this.$store.state.account.projectIdSelected;
    }
  }
};
exports.default = _default;