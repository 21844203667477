"use strict";

var _interopRequireDefault = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ThemePicker: _ThemePicker.default
  },
  data: function data() {
    return {
      flag: this.$store.state.setting.settingBar.opened
    };
  },
  computed: {
    sidebarColor: {
      get: function get() {
        return this.$store.state.setting.sideBarTheme;
      },
      set: function set(val) {
        this.$store.commit('setting/setSideBarTheme', val);
        this.$put('system/user/theme', {
          theme: val
        });
      }
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.setting.fixHeader;
      },
      set: function set(val) {
        this.$store.commit('setting/fixHeader', val);
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.setting.multipage;
      },
      set: function set(val) {
        this.$store.commit('setting/setMultipage', val);
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.setting.sidebarLogo;
      },
      set: function set(val) {
        this.$store.commit('setting/setSidebarLogo', val);
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.commit('setting/setTheme', val);
    },
    handleClose: function handleClose() {
      this.$store.commit('setting/openSettingBar', false);
    }
  }
};