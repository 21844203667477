"use strict";

var _interopRequireDefault = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _index = require("@/lang/index");
var _default = exports.default = {
  namespaced: true,
  state: {
    settingBar: {
      opened: false
    },
    sidebar: {
      opened: _localstorage.default.get('SIDEBAR_STATUS', true),
      withoutAnimation: false
    },
    device: 'desktop',
    language: (0, _index.getLanguage)(),
    sidebarLogo: _localstorage.default.get('SIDEBAR_LOGO', true),
    multipage: _localstorage.default.get('MULTIPAGE', true),
    fixSiderbar: _localstorage.default.get('FIX_SIDERBAR', true),
    fixHeader: _localstorage.default.get('FIX_HEADER', true),
    colorList: ['rgb(245, 34, 45)', 'rgb(250, 84, 28)', 'rgb(250, 173, 20)', 'rgb(66, 185, 131)', 'rgb(82, 196, 26)', 'rgb(24, 144, 255)', 'rgb(47, 84, 235)', 'rgb(114, 46, 209)'],
    color: _localstorage.default.get('COLOR', 'rgb(24, 144, 255)'),
    theme: '#1890FF',
    sideBarTheme: _localstorage.default.get('SIDEBAR_THEME', true),
    // 地图换算比例
    mapRate: 10,
    sideBarPath: _localstorage.default.get('SIDEBAR_PATH', [])
  },
  mutations: {
    toggleSidebar: function toggleSidebar(state) {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        _localstorage.default.save('SIDEBAR_STATUS', 1);
      } else {
        _localstorage.default.save('SIDEBAR_STATUS', 0);
      }
    },
    closeSidebar: function closeSidebar(state, withoutAnimation) {
      _localstorage.default.save('SIDEBAR_STATUS', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    openSettingBar: function openSettingBar(state, val) {
      state.settingBar.opened = val;
    },
    setMultipage: function setMultipage(state, multipage) {
      _localstorage.default.save('MULTIPAGE', multipage);
      state.multipage = multipage;
    },
    fixSiderbar: function fixSiderbar(state, val) {
      _localstorage.default.save('FIX_SIDERBAR', val);
      state.fixSiderbar = val;
    },
    fixHeader: function fixHeader(state, val) {
      _localstorage.default.save('FIX_HEADER', val);
      state.fixHeader = val;
    },
    setSettingBar: function setSettingBar(state, val) {
      state.settingBar.opened = val;
    },
    setColor: function setColor(state, color) {
      _localstorage.default.save('COLOR', color);
      state.color = color;
    },
    setLanguage: function setLanguage(state, language) {
      _localstorage.default.save('LANGUAGE', language);
      state.language = language;
    },
    toggleDevice: function toggleDevice(state, device) {
      state.device = device;
    },
    setSidebarLogo: function setSidebarLogo(state, val) {
      _localstorage.default.save('SIDEBAR_LOGO', val);
      state.sidebarLogo = val;
    },
    setTheme: function setTheme(state, val) {
      state.theme = val;
    },
    setSideBarTheme: function setSideBarTheme(state, val) {
      _localstorage.default.save('SIDEBAR_THEME', val);
      state.sideBarTheme = val;
    },
    setSideBarPath: function setSideBarPath(state, val) {
      _localstorage.default.save('SIDEBAR_PATH', val);
      state.sideBarPath = val;
    }
  }
};