"use strict";

var _interopRequireDefault = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getLanguage = getLanguage;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _objectSpread2 = _interopRequireDefault(require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _en2 = _interopRequireDefault(require("./en"));
var _zh = _interopRequireDefault(require("./zh"));
// element-ui lang
// element-ui lang

_vue.default.use(_vueI18n.default);
var messages = {
  en: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _en2.default), _en.default),
  zh: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _zh.default), _zhCN.default)
};
function getLanguage() {
  var chooseLanguage = _localstorage.default.get('LANGUAGE', '');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'zh';
}
var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages,
  silentTranslationWarn: true
});
var _default = exports.default = i18n;