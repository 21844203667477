"use strict";

var _interopRequireDefault = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasPermission = exports.hasNoPermission = exports.hasAnyPermission = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
// 定义一些和权限有关的 Vue指令

// 必须包含列出的所有权限，元素才显示
var hasPermission = exports.hasPermission = {
  install: function install(Vue) {
    Vue.directive('hasPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        var flag = true;
        // 暂时取消权限控制
        // for (const v of value) {
        //   if (!permissions.includes(v)) {
        //     flag = false
        //   }
        // }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 当不包含列出的权限时，渲染该元素
var hasNoPermission = exports.hasNoPermission = {
  install: function install(Vue) {
    Vue.directive('hasNoPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        var flag = true;
        var _iterator = (0, _createForOfIteratorHelper2.default)(value),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var v = _step.value;
            if (permissions.includes(v)) {
              flag = false;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 只要包含列出的任意一个权限，元素就会显示
var hasAnyPermission = exports.hasAnyPermission = {
  install: function install(Vue) {
    Vue.directive('hasAnyPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        // 暂时取消权限控制
        // let flag = false
        // for (const v of value) {
        //   if (permissions.includes(v)) {
        //     flag = true
        //   }
        // }
        var flag = true;
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};