"use strict";

var _interopRequireWildcard = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/core-js/modules/es6.promise.js");
require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/smile/Documents/work/ecare/ecare-web/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
require("@/theme/index.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./utils/error-log");
var _request = _interopRequireDefault(require("@/utils/request"));
var filters = _interopRequireWildcard(require("./filters"));
var _permissionDirect = require("./utils/permissionDirect");
// a modern alternative to CSS resets

// global css

//主题色修改

// internationalization
// icon
// error log

// global filters

var Plugins = [_permissionDirect.hasPermission, _permissionDirect.hasNoPermission, _permissionDirect.hasAnyPermission];
Plugins.map(function (plugin) {
  _vue.default.use(plugin);
});
_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.prototype.$post = _request.default.post;
_vue.default.prototype.$get = _request.default.get;
_vue.default.prototype.$put = _request.default.put;
_vue.default.prototype.$delete = _request.default.delete;
_vue.default.prototype.$download = _request.default.download;
_vue.default.prototype.$downloadExcel = _request.default.downloadExcel;
_vue.default.prototype.$upload = _request.default.upload;
_vue.default.prototype.$login = _request.default.login;

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});