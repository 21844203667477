var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sidebar-header" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/logo.png"), alt: "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c("lang-select", {
                  staticClass: "right-menu-item hover-effect",
                  staticStyle: { display: "none" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar, alt: "avatar" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.username)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("navbar.profile")) +
                            "\n              "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.deleteCache },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.deleteCache")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }