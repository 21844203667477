var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "has-logo": _vm.showLogo,
        "has-select": (_vm.type == 2 || _vm.type == 3) && _vm.tenantId != 1,
      },
    },
    [
      (_vm.type == 2 || _vm.type == 3) && _vm.tenantId != 1
        ? _c(
            "div",
            { staticClass: "select-box" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeProject },
                  model: {
                    value: _vm.projectIdSelected,
                    callback: function ($$v) {
                      _vm.projectIdSelected = $$v
                    },
                    expression: "projectIdSelected",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return item.status == 1
                    ? _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": true,
                mode: "vertical",
              },
            },
            _vm._l(_vm.userRoutes, function (route) {
              return _c("sidebar-item", {
                key: route.path + (route.meta ? route.meta.title : ""),
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }